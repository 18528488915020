import { useCities } from '~/store/cites/cities.store'

export default async function fetchTextAgg(input?, allInput?) {
  const ctx = useNuxtApp()
  const cityStore = useCities()
  const currentCity = computed(() => cityStore.getCity)
  const client = ctx._apolloClients.default
  const res = await client.query({
    query: gql`
      query getTextAgg(
        $input: LocationInfoInput!
        $tariffsAggInput2: BaseTariffsInput
        $groupInput2: BaseTariffsInput
        $groupInput3: BaseTariffsInput = { sort: FAST, pageSize: 1 }
        $groupInput4: BaseTariffsInput = { sort: CHEAP, pageSize: 1 }
        $groupInput5: BaseTariffsInput = { sort: MOST_TV_CHANNELS, pageSize: 1 }
        $groupInput6: BaseTariffsInput = {
          sort: FAST
          uniqueByProvider: true
          pageSize: 3
        }
        $groupInput7: BaseTariffsInput = {
          sort: CHEAP
          uniqueByProvider: true
          pageSize: 3
        }
        $groupInput8: BaseTariffsInput = {
          sort: POPULAR
          uniqueByProvider: true
          pageSize: 3
        }
      ) {
        location {
          locationTerms(input: $input) {
            location {
              linkHousesCount
            }
            tariffsAgg(input: $tariffsAggInput2) {
              tariffsCount
              providersCount
              availableHouseTypes
              availableProviders {
                id
                name
              }
              availableTechs {
                id
                name
              }
              priceRange {
                min
                max
              }
              speedRange {
                min
                max
              }
              tvChannelsRange {
                max
              }
              mobileGigabytesRange {
                max
              }
              mobileMinutesRange {
                max
              }
              availableSubscriptionServices {
                id
                name
              }
              maxDiscountPercent
              locationAgg {
                averagePriceForMostOptimalSpeed
                mostOptimalSpeed
                availableSmartFilters {
                  slug
                  name
                  tariffsInput
                  asQueryParam
                }
              }
            }
            groupedTariffs {
              allInOne: group(input: $groupInput2) {
                agg {
                  availableProviders {
                    name
                  }
                  priceRange {
                    max
                    min
                  }
                  speedRange {
                    max
                    min
                  }
                  tariffsCount
                }
              }
              fastestTariff: group(input: $groupInput3) {
                data {
                  id
                  name
                  services {
                    internetSpeed
                  }
                  provider {
                    name
                  }
                }
              }
              cheapestTariff: group(input: $groupInput4) {
                data {
                  id
                  name
                  payment {
                    effectivePrice
                  }
                  provider {
                    name
                  }
                }
              }
              mostTvTariff: group(input: $groupInput5) {
                data {
                  id
                  name
                  services {
                    tvChannelsCount
                  }
                  provider {
                    name
                  }
                }
              }
              fastestProviders: group(input: $groupInput6) {
                data {
                  provider {
                    id
                    name
                  }
                }
              }
              cheapestProviders: group(input: $groupInput7) {
                data {
                  provider {
                    id
                    name
                  }
                }
              }
              popularProviders: group(input: $groupInput8) {
                data {
                  provider {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      input: {
        hash: undefined,
        addrObjFiasId: currentCity.value?.fias_id,
        strictTechs: false,
        ...input,
      },
      ...allInput,
    },
  })

  return res.data.location.locationTerms
}
